.header {
  min-height: 50vh;
  background-size: cover;
  background-position: center;
  text-shadow: 5px 5px 30px var(--dark-color);
}

.header {
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
}

.header h1 {
  line-height: 3rem;
}

@media screen and (min-width: 768px){
  .header {
    min-height: 75vh;
  }

  .header h1 {
    line-height: 4.25rem;
  }
}

@media screen and (min-width: 1280px){
  .header h1 {
    line-height: 5.5rem
  }
}

.circle {
  font-size: 3rem;
  @apply max-w-icon-little md:max-w-icon;
}

.value-item {
  font-weight: 700;
  @apply text-xl md:text-3xl flex flex-col items-center gap-2 md:gap-6 text-dark-color;
}

/* .gallery-preview {
  height: 300px;
} */

.gallery-preview {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
}

.gallery-preview img {
  position: absolute;
  height: 100%;
}