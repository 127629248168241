.logo {
    max-width: 100px;
}

.nav-container .nav-link:hover {
    @apply text-yellow;
}

.burger-menu {
    width: 50px;
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
}

.burger-menu div {
    height: 3px;
    @apply bg-dark-color w-full z-10;
}

.nav-container {
    position: sticky;
    top: 0;
    position: -webkit-sticky;
    height: 64px;
}

@media screen and (min-width: 768px){
    
}