@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/Home.module.css';
@import './styles/Nav.module.css';
@import './styles//Projects.module.css';

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

    --yellow: #fbcd37;
    --grey: #e8e9eb;
    --dark-color: #24252C;

    --tablet-width: 768px;
    --desktop-width: 1280px;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  font-family: "Poppins", sans-serif;
  @apply text-dark-color;
  /* overflow: hidden;
  overflow-y: scroll; */
}

a {
  color: inherit;
  text-decoration: none;
  position: inherit;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

img {
  display: block;
  width: 100%;
}

::selection {
  background: var(--yellow);
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

.common-link {
  z-index: 2;
}

.common-link::before {
  content: '';
  background: var(--yellow);
  height: 15%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  transition: .1s ease-out;
  @apply md:-right-2 md:-left-2 absolute;
}

.common-link:hover::before {
  height: 100%;
}

.common-text {
  @apply md:text-xl
}

.layout {
  @apply xl:max-w-desktop-layout mx-auto px-4 xl:px-0;
}

.with-mb {
  @apply mb-20 md:mb-32;
}

.with-mb-special {
  @apply mb-20 md:mb-96;
}

.layout.article {
  @apply max-w-tablet-layout;
}

.job-item {
  @apply mb-8 md:mb-14;
}

.job-title {
  @apply text-xl md:text-4xl font-bold mb-6;
}

.job-title-section {
  @apply text-lg md:text-xl font-bold mb-2;
}

.main-title {
  @apply text-3xl md:text-5xl font-bold mb-6 md:mb-20;
}

.main-title.title-jobs {
  @apply md:mb-12;
}

.job-item {
  border: 1px solid rgba(37, 38, 45, 0.2);
  box-shadow: 4px 2px 15px rgba(37, 38, 45, 0.1);
  border-radius: 10px;
  @apply py-4 px-4 md:px-6 md:py-8;
}

.main-title-project {
  @apply text-3xl md:text-8xl font-bold mb-6;
}

/* .contact-content {
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
} */

.banner {
  background: linear-gradient(160deg, #6DBFAA 0%, #038F9A 100%);
}

.contact-map {
  width: 100%;
  height: 500px;
}

iframe {
  height: 100%;
}

@media screen and (min-width: 768px){
  .contact-map {
    width: 500px;
  }
}
